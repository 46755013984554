export const SET_STATE = (state, { key, value }) => {
  state[key] = value;
};

export const INITIALIZE = (state) => {
  state.isInitialized = true;
};

export const RESET_STATE = (state) => {
  state.users = [];
  state.ownerList = [];
  state.isInitialized = false;
  state.isLoading = false;
  state.error = null;
  state.uid = null;
};
